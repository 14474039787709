@import '../../style/variables.scss';

.endsupport-modal {
  .disclaimer-wrapper {
    cursor: default;

    h2 {
      margin-bottom: 5px;
    }

    p {
      line-height: 20px;
      margin-bottom: 5px;
    }

    a {
      display: block;
      margin-top: 5px;
      text-decoration: underline;
      color: $color-dark-blue;
      cursor: pointer;
    }

    .buttons {
      display: flex;
      margin-top: 25px;

      button {
        margin-left: auto;
        padding: 10px 20px;
      }

      .reminder {
        display: block;
        margin: 5px 0px;
        user-select: none;
        label {
          margin-top: 3px;
          color: $color-light-gray;
          display: flex;
        }
        input {
          display: block;
          width: 19px;
          height: 19px;
          padding: 0;
          margin: 0;
          position: relative;
          top: 0px;
          accent-color: $color-primary;
          margin-right: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .bridgelock-modal {
    .disclaimer-wrapper {
      h2 {
        margin-bottom: 10px;
      }
      .buttons {
        margin-top: 20px;
        display: block;
        button {
          margin-top: 10px;
          padding: 10px;
          width: 100%;
        }
      }
    }
  }
}
